<template>
  <div>
    <div>
      <b-button v-b-toggle.collapse-1 variant="primary">Filtros avançados</b-button>
      <b-collapse id="collapse-1" class="mt-2">
        <b-card>
          <b-row>
            <b-col cols="3">
                <b-form-group label="Imóvel" label-for="immobile">
                    <b-form-input v-model="filters.immobileNameFilter" />
                </b-form-group>
            </b-col>
            <b-col cols="3">
               <b-form-group label="Investidor" label-for="immobile">
                  <b-form-input v-model="filters.investorNameFilter"/>
               </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Aquisição Inicio" label-for="payment-type">
                  <b-input-group>
                    <b-form-input
                      id="acquisitionStartFilter"
                      v-model="filters.acquisitionStartFilter"
                      v-mask="'##/##/####'"
                      placeholder=""/>
                        <b-input-group-append>
                          <b-input-group-text>
                            <b-link @click="filters.acquisitionStartFilter = undefined">
                              <b-icon icon="x" />
                            </b-link>
                          </b-input-group-text>
                        </b-input-group-append>
                  </b-input-group>                    
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Aquisição Fim" label-for="payment-type">
                   <b-input-group>
                    <b-form-input
                      id="acquisitionFinishFilter"
                      v-model="filters.acquisitionFinishFilter"
                      v-mask="'##/##/####'"
                      placeholder=""/>
                      <b-input-group-append>
                        <b-input-group-text>                          
                            <b-link @click="filters.acquisitionFinishFilter = undefined">
                              <b-icon icon="x" />
                            </b-link>
                        </b-input-group-text>
                      </b-input-group-append>
                   </b-input-group>
                </b-form-group>
            </b-col>           
            <b-col cols="2">
                <b-form-group label="Status da Cota" label-for="status-cota">
                    <b-form-select
                        id="status-cota"
                        v-model="filters.situationFilter"
                        label="Status da Cota"
                        :options="listStatusCota" />
                </b-form-group>
            </b-col>
          </b-row>
          <b-row> 
            <b-col cols="4">
                <b-form-group label="Corretor" label-for="Corretor">
                    <b-form-input v-model="filters.brokerNameFilter" />
                </b-form-group>
            </b-col>
            
            <b-col cols="2">
                <b-form-group label="Comissão Inicio" label-for="payment-type">
                   <b-input-group>
                    <b-form-input
                      id="acquisitionFinishFilter"
                      v-model="filters.commissionStartFilter"
                      v-mask="'##/##/####'"
                      placeholder=""/>
                      <b-input-group-append>
                        <b-input-group-text>                          
                            <b-link @click="filters.commissionStartFilter = undefined">
                              <b-icon icon="x" />
                            </b-link>
                        </b-input-group-text>
                      </b-input-group-append>
                   </b-input-group>                   
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Comissão Fim" label-for="payment-type">
                   <b-input-group>
                    <b-form-input
                      id="acquisitionFinishFilter"
                      v-model="filters.commissionFinishFilter"
                      v-mask="'##/##/####'"
                      placeholder=""/>
                    <b-input-group-append>
                      <b-input-group-text>
                        <b-link @click="filters.commissionFinishFilter = undefined">
                          <b-icon icon="x" />
                        </b-link>
                      </b-input-group-text>
                    </b-input-group-append>
                   </b-input-group>
                </b-form-group>
            </b-col>        
            <b-col cols="2">
                <b-form-group label="Status da comissão" label-for="status-comissao">
                    <b-form-select
                        id="status-comissao"
                        v-model="filters.commissionStatusFilter"
                        label="comissao"
                        :options="listStatusComissao"/>
                </b-form-group>
            </b-col>  
            </b-row>
            <b-row> 
              <b-col cols="1" class="aling-buttons">
                <b-button variant="primary" @click="clear()">Limpar</b-button>  
              </b-col>
              <b-col cols="1" class="aling-buttons">
                <b-button variant="primary" @click="getInvestments()">Pesquisar</b-button>  
              </b-col>
            </b-row>
        </b-card>
      </b-collapse>
    </div>
    <vue-good-table
      id="table_investments"
      :columns="columns"
      :rows="investments" 
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: search,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      @on-sort-change="sortChange">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'immobileName'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.immobileName }}</span>
        </span>

        <span v-else-if="props.column.field === 'userInvestorProfileName'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.userInvestorProfileName }}</span>
        </span>

        <span v-else-if="props.column.field === 'quotaValue'" class="text-nowrap">
          <span class="text-nowrap">{{ formatCurrencyPrice(props.row.investmentQuota.quotaValue) }}</span>
        </span>

        <span v-else-if="props.column.field === 'situation'" class="situation text-nowrap">
          <b-badge :variant="statusInvestmentVariant(props.row.investmentQuota.situation)">
              {{ statusInvestmentVariantName(props.row.investmentQuota.situation) }}
            </b-badge>
        </span>
        
        <span v-else-if="props.column.field === 'userBrokerProfileName'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.investmentAssociatedBroker.name }}</span>
        </span>
        
        <span v-else-if="props.column.field === 'commissionValue'" class="text-nowrap">
          <span class="text-nowrap">{{ formatCurrencyPrice(props.row.investmentAssociatedBroker.value) }}</span>
        </span>
        
        <span v-else-if="props.column.field === 'commissionStatus'" class="text-nowrap">
          <b-badge v-if="props.row.investmentAssociatedBroker.status !== 0" :variant="statusComissionVariant(props.row.investmentAssociatedBroker.status)">
              {{ statusComissionVariantName(props.row.investmentAssociatedBroker.status) }}
            </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-link v-b-tooltip.hover.v-secondary title="Editar Investimento" :to="{ name: 'manager-quotas-details', params: { id: props.row.investmentQuota.id, path: `/manager-quotas-details/${props.row.investmentQuota.id}` }, }">
            <feather-icon icon="Edit2Icon" class="mr-50" />
          </b-link>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ totalCount }} Resultados </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {  BInputGroupText, BInputGroupAppend, BPagination, BFormSelect, BLink, VBTooltip, BBadge, BIcon, BCard, BCollapse, VBToggle, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup} from 'bootstrap-vue'
import BackgroundCard from '../../template/BackgroundCard.vue'
import InvestmentService from '@/services/Investment/InvestmentService'
import { VueGoodTable } from 'vue-good-table'
import { codeBasic } from '../../codeTable'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import formatter from '@/mixins/formatter'
import SearchBarVue from '@/layouts/navbar/components/SearchBar.vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import InvestmentSituationEnum from '@/enums/InvestmentSituationEnum'

export default {
  components: {
    BInputGroupText, 
    BInputGroupAppend,
    BInputGroup,
    BPagination,
    BackgroundCard,
    BFormSelect,
    BCard,
    BCollapse,
    BButton,
    BLink,
    BBadge,
    VueGoodTable,
    BIcon,
    SearchBarVue, 
    BFormGroup, 
    BRow, 
    BCol,
    BFormInput
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  
  mixins:[formatter],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,    
    'b-toggle': VBToggle
  },
  data: () => ({
    investments: [],
    pageLength: 10,
    currentPage: 1,
    dir: false,
    sorting: '',
    codeBasic,
    totalCount: 0,
    filters: {},
    search: '',
    columns: [
      {
        label: 'Imóvel',
        field: 'immobileName'
      },
      {
        label: 'Investidor',
        field: 'userInvestorProfileName',
      },
      {
        label: 'Valor Cota',
        field: 'quotaValue',
      },
      {
        label: 'Status Cota',
        field: 'situation',
      },
      {
        label: 'Corretor',
        field: 'userBrokerProfileName',
      },
      {
        label: 'Valor Comissão',
        field: 'commissionValue',
      },
      {
        label: 'Status Comissão',
        field: 'commissionStatus',
      },
      {
        label: '',
        field: 'action',
      },
    ],
    config: {
      dateFormat: 'd/m/Y',
      locale: Portuguese,
    },
    listStatusCota: InvestmentSituationEnum.listStatusCota,
    listStatusComissao: InvestmentSituationEnum.listStatusComissao,
  }),
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
    statusInvestmentVariant() {
      const statusColor = {
        1: 'light-primary',
        2: 'light-primary',
        3: 'light-success',
        4: 'light-danger',
      }

      return status => statusColor[status]
    },  
    
    statusComissionVariant() {
      const statusColor = {
        1: 'light-success',
        2: 'light-primary',
      }

      return status => statusColor[status]
    },    
    statusInvestmentVariantName() {
      const statusName = {
        1: 'Pendente Pagamento',
        2: 'Pendente Assinatura',
        3: 'Pago',
        4: 'Cancelado'
      }
      return status => statusName[status]
    },
    statusComissionVariantName() {
      const statusName = {
        1: 'Pago',
        2: 'Pendente',
      }
      return status => statusName[status]
    }
  },

  watch: {
    currentPage: {
      handler(val) {
        this.getInvestments()
      },
      immediate: true,
      }
  },

  methods: {
    async getInvestments(){
      let filters = this.getFilters()
      filters = `${filters}&SkipCount=${(10 * this.currentPage) - 10}&Sorting=${this.sorting}`
      const result = (await InvestmentService.getAll(filters)).data.result
      this.investments = result.items
      this.totalCount = result.totalCount
    },

    sortChange(event) {
      this.sorting =  event[0].field + ' ' + event[0].type
      this.getInvestments()
    },

    getFilters(){
      let filters = ``
      if(this.filters.immobileNameFilter !== undefined) {
        filters = `${filters}&immobileNameFilter=${this.filters.immobileNameFilter}`
      }
      if(this.filters.investorNameFilter !== undefined) {
        filters = `${filters}&investorNameFilter=${this.filters.investorNameFilter}`
      }
      if(this.filters.acquisitionStartFilter !== undefined) {
        filters = `${filters}&acquisitionStartFilter=${formatter.formatToDate(this.filters.acquisitionStartFilter)}`
      }
      if(this.filters.acquisitionFinishFilter !== undefined) {
        filters = `${filters}&acquisitionFinishFilter=${formatter.formatToDate(this.filters.acquisitionFinishFilter)}`
      }
      if(this.filters.situationFilter !== undefined) {
        filters = `${filters}&situationFilter=${this.filters.situationFilter}`
      }
      if(this.filters.brokerNameFilter !== undefined) {
        filters = `${filters}&brokerNameFilter=${this.filters.brokerNameFilter}`
      }
      if(this.filters.commissionStartFilter !== undefined) {
        filters = `${filters}&commissionStartFilter=${this.filters.commissionStartFilter}`
      }
      if(this.filters.commissionFinishFilter !== undefined) {
        filters = `${filters}&commissionFinishFilter=${this.filters.commissionFinishFilter}`
      }
      if(this.filters.commissionStatusFilter !== undefined) {
        filters = `${filters}&commissionStatusFilter=${this.filters.commissionStatusFilter}`
      }
      return filters
    },

    clear(){
      this.filters = {}
      this.getInvestments()
    }

  },

  async mounted() {
    this.getInvestments()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.badge {
  width: 100% !important;
}
.text-center-position {
  margin-left: 45%;
}
body {
  background-color: #f8f8f8 !important;
}
#table_investments th, tbody td{
}


#table_investments th:nth-child(1) { width: 20% !important; left: 0.5% !important;  }
#table_investments th:nth-child(2) { width: 15% !important;   }
#table_investments th:nth-child(3) { width: 15% !important;   }
#table_investments th:nth-child(4) { width: 15% !important; left: 0.5% !important;  }
#table_investments th:nth-child(5) { width: 15% !important;   }
#table_investments th:nth-child(6) { width: 10% !important;   }
#table_investments th:nth-child(7) { width: 10% !important;   }
#table_investments th:nth-child(8) { width: 5% !important;   }

#table_investments table tbody td:nth-child(1)  { width: 15% !important;   }
#table_investments table tbody td:nth-child(2)  { width: 17% !important;   }
#table_investments table tbody td:nth-child(3)  { width: 13% !important;   }
#table_investments table tbody td:nth-child(4)  { width: 17% !important;   }
#table_investments table tbody td:nth-child(5)  { width: 16% !important;   }
#table_investments table tbody td:nth-child(6)  { width: 10% !important;   }
#table_investments table tbody td:nth-child(7)  { width: 10% !important;   }
#table_investments table tbody td:nth-child(8)  { width: 5% !important;   }

#table_investments table.vgt-table {
  background-color: #f8f8f8 !important;
}

#table_investments a { color: #828282; }
#table_investments a:hover { color: var(--color); }

.space-icon { margin-left: 10px; }


.aling-buttons{
  margin-top: 1.6%;
}
.button-left{
  margin-left: 1%!important;
  margin-right: 1% !important;
}
@media (max-width: 1450px) {

#table_investments table tbody td:nth-child(3)  { width: 12% !important;   }
#table_investments table tbody td:nth-child(4)  { width: 15% !important;   }

}
@media (min-width: 1451px) {

  #table_investments th:nth-child(1) { width: 16% !important; left: 0.5% !important;  }
  #table_investments th:nth-child(2) { width: 16% !important;   }

  #table_investments table tbody td:nth-child(2)  { width: 16% !important;   }
  #table_investments table tbody td:nth-child(3)  { width: 15% !important;   }
  #table_investments table tbody td:nth-child(4)  { width: 14% !important;   }
  #table_investments table tbody td:nth-child(5)  { width: 17% !important;   }

}

@media (min-width: 1800px) {

  #table_investments th:nth-child(1) { width: 18% !important;   }
  #table_investments th:nth-child(2) { width: 17% !important;   }
  #table_investments th:nth-child(3) { width: 13% !important;   }
  #table_investments th:nth-child(4) { width: 15% !important;   }
  #table_investments th:nth-child(5) { width: 14% !important;   }
  #table_investments th:nth-child(6) { width: 16% !important;   }

  #table_investments table tbody td:nth-child(1)  { width: 17% !important;   }
  #table_investments table tbody td:nth-child(2)  { width: 17.1% !important;   }
  #table_investments table tbody td:nth-child(3)  { width: 12% !important;   }
  #table_investments table tbody td:nth-child(4)  { width: 12% !important;   }
  #table_investments table tbody td:nth-child(7)  { width: 15% !important;   }

}
</style>
